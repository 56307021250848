<template>
  <div class="FollowedStreamsManagerItem">
    <div class="columns">
      <div class="column">
        <div class="buttons">
          <v-button
            :loading="isUnfollowingAll"
            :disabled="brand.user_followed_streams_count === 0"
            class="is-dark"
            data-testid="UnfollowAllStreams"
            @click="unfollowAll"
          >
            {{ $t('components.followed_streams_manager_item.unfollow_all') }}
          </v-button>
          <v-button
            :loading="isFollowingAll"
            :disabled="allStreamsFollowed"
            class="is-primary"
            data-testid="FollowAllStreams"
            @click="followAll"
          >
            {{ $t('components.followed_streams_manager_item.follow_all') }}
          </v-button>
        </div>
      </div>
      <div v-loading="isLoadingAutoFollow" class="column is-flex">
        <div class="is-expanded">
          {{ $t('components.followed_streams_manager_item.autofollow_streams') }}
        </div>
        <el-switch
          v-model="isAutoFollowingStreams"
          :active-text="$t('general.on')"
          data-testid="StreamAutoFollowingSwitch"
        />
      </div>
    </div>
    <div v-loading="isLoading">
      <followed-streams-manager-stream
        v-for="stream in streams"
        :key="stream.id"
        :stream="stream"
        @follow-change="handleStreamFollowChange"
      />
    </div>
  </div>
</template>

<script>
import { BrandApiService } from '@hypefactors/shared/js/services/api/BrandApiService'
import { StreamsApiService } from '@hypefactors/shared/js/services/api/StreamsService'
import FollowedStreamsManagerStream from '@/pages/settings/components/FollowedStreamsManagerStream'

/**
 * @module FollowedStreamsManagerItem
 */
export default {
  name: 'FollowedStreamsManagerItem',
  components: { FollowedStreamsManagerStream },
  props: {
    brand: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      isLoadingAutoFollow: false,
      isFollowingAll: false,
      isUnfollowingAll: false,
      alreadyFetched: false,
      streams: [],
      cacheIsAutoFollowingStreams: false
    }
  },
  computed: {
    /**
     * Whether all streams are followed
     * @return {boolean}
     */
    allStreamsFollowed () {
      return this.brand.user_followed_streams_count === this.brand.streams_count
    },
    /**
     * Switches the auto-following of streams on and off.
     */
    isAutoFollowingStreams: {
      get () {
        return this.cacheIsAutoFollowingStreams
      },
      set (value) {
        this.setAutoStreamFollow(value)
      }
    }
  },
  watch: {
    /**
     * If the brand has steams, and they are not already fetched, fetch them
     * @param {boolean} value
     */
    isVisible (value) {
      if (this.brand.streams_count && value && !this.alreadyFetched) {
        this.fetchStreams()
      }
    }
  },
  mounted () {
    this.cacheIsAutoFollowingStreams = this.brand.auto_follow_newly_created_streams
  },
  methods: {
    /**
     * Fetches all the streams in a collection
     * @return {Promise<void>}
     */
    async fetchStreams () {
      this.isLoading = true
      try {
        this.streams = await StreamsApiService.fetchAll({
          params: {
            include: 'brand',
            brands: [this.brand.id]
          }
        })
        this.alreadyFetched = true
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * Sets auto-following of a stream
     * @param {boolean} status
     * @return {Promise<void>}
     */
    async setAutoStreamFollow (status) {
      try {
        this.cacheIsAutoFollowingStreams = status
        this.isLoadingAutoFollow = true
        await BrandApiService.autoFollowAllStreams(this.brand.id, status)
        // Set the auto follow stream status on the brand
        this.updateBrand({
          auto_follow_newly_created_streams: status
        })
      } catch (err) {
        this.cacheIsAutoFollowingStreams = this.brand.auto_follow_newly_created_streams
      } finally {
        this.isLoadingAutoFollow = false
      }
    },
    /**
     * Updates a brand's user followed streams count on each stream follow change
     * @param followedStatus
     */
    handleStreamFollowChange (followedStatus) {
      const newCountStatus = followedStatus ? 1 : -1
      this.updateBrand({
        user_followed_streams_count: this.brand.user_followed_streams_count + newCountStatus
      })
    },
    /**
     * Follow all streams
     * @return {Promise<void>}
     */
    async followAll () {
      this.isFollowingAll = true
      try {
        await BrandApiService.followAllStreams(this.brand.id)
        // set all user followed streams to equal the total amount of streams
        this.updateBrand({ user_followed_streams_count: this.brand.streams_count })
        // set each stream as followed
        this.streams.forEach((stream) => {
          stream.followed_by_user = true
        })
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isFollowingAll = false
      }
    },
    /**
     * Unfollows all the streams on a brand
     * @return {Promise<void>}
     */
    async unfollowAll () {
      this.isUnfollowingAll = true
      try {
        await BrandApiService.unfollowAllStreams(this.brand.id)
        // updates user followed streams counter on the brand to 0
        this.updateBrand({ user_followed_streams_count: 0 })
        // sets all streams to be not followed by the user
        this.streams.forEach((stream) => {
          stream.followed_by_user = false
        })
      } catch (e) {
        this.$displayRequestError(e)
      } finally {
        this.isUnfollowingAll = false
      }
    },
    /**
     * Propagates a change in the brand data to the parent
     * @param {object} data
     */
    updateBrand (data) {
      this.$emit('update:brand', {
        ...this.brand,
        ...data
      })
    }
  }
}
</script>
